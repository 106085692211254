import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { Alert, Button } from '@golfersweden/pegora-ui';
import { Auth } from 'aws-amplify';
import DefaultLayout from '../layouts/Default';
import Box from '../components/elements/Box';
import InitForgotPassword from '../components/forms/InitForgotPassword';
import CompleteForgotPassword from '../components/forms/CompleteForgotPassword';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 600px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ff5564;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const WelcomeTitle = styled.h1`
  color: white;
  text-align: center;
`;

const IntroText = styled.p`
  text-align: center;
  font-size: 16px;
  color: white;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const ForgotPassword = () => {
  const params = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false);
  const [redirectTo] = useState();
  const [feedback, setFeedback] = useState();
  const [username, setUsername] = useState();
  const [code, setCode] = useState();

  const [display, setDisplay] = useState('INIT_FORM');

  const init = async username => {
    setLoading(true);
    try {
      await Auth.forgotPassword(username);
      setFeedback({
        type: 'success',
        message: 'We have sent you an email with a confirmation code that you can use for setting a new password.'
      });

      setUsername(username);
      setDisplay('CHANGE_PASSWORD_FORM');
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        setFeedback({ type: 'error', message: 'We could not find a matching user with the username you provided.' });
      } else {
        setFeedback({ type: 'error', message: 'An unexpected error occurred' });
      }
    }

    setLoading(false);
  };

  const submit = async (username, code, newPassword) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      setFeedback({
        type: 'success',
        message: 'Your password has been updated!'
      });
      setDisplay('SUCCESS');
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        setFeedback({ type: 'error', message: 'We could not find a matching user with the username you provided.' });
      } else {
        setFeedback({ type: 'error', message: 'An unexpected error occurred' });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    const codeParam = params.get('code');
    const usernameParam = params.get('username');

    if (usernameParam && codeParam) {
      setUsername(usernameParam);
      setCode(codeParam);
      setDisplay('CHANGE_PASSWORD_FORM');
    }
  }, [params]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <DefaultLayout>
      <Box>
        <InnerContainer>
          <LeftSection>
            <h2>Forgot Password</h2>
            <Fragment>
              <p>Fill in your username and we'll send you instructions on how to set a new password.</p>
              {feedback && <StyledAlert message={feedback.message} type={feedback.type} />}
              {display === 'INIT_FORM' && (
                <Fragment>
                  <InitForgotPassword loading={loading} onSubmit={init} />
                  <Link to="#" onClick={() => setDisplay('CHANGE_PASSWORD_FORM')}>
                    Already got a code?
                  </Link>
                </Fragment>
              )}
              {display === 'CHANGE_PASSWORD_FORM' && (
                <CompleteForgotPassword username={username} code={code} loading={loading} onSubmit={submit} />
              )}
              {display === 'SUCCESS' && (
                <Link to="/login">
                  <Button ghost>Sign In</Button>
                </Link>
              )}
            </Fragment>
          </LeftSection>
          <RightSection>
            <WelcomeTitle>Already have your password?</WelcomeTitle>
            <IntroText>If you already know your password, click below to go back to the sign in page.</IntroText>
            <Link to="/login">
              <Button ghost>Sign In</Button>
            </Link>
          </RightSection>
        </InnerContainer>
      </Box>
    </DefaultLayout>
  );
};

export default ForgotPassword;
