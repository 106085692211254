import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Redirect, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, Icon, Alert } from '@golfersweden/pegora-ui';
import DefaultLayout from '../layouts/Default';
import Box from '../components/elements/Box';
import LoginForm from '../components/forms/Login';
import AuthContext from '../components/auth/AuthContext';

const logo = require('../assets/logo_icon_coral.png');

const Logo = styled.img`
  width: 100px;
  margin-bottom: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px;
  height: 600px;
`;

const SignInTitle = styled.h1`
  font-size: 36px;
  font-weight: '800';
  margin: 10px 0px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #1e1a4d;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const WelcomeTitle = styled.h1`
  color: white;
`;

const IntroText = styled.p`
  text-align: center;
  font-size: 16px;
  color: white;
`;

const SignInDescription = styled.p`
  color: #aeaeae;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const SocialMediaIcon = styled(Icon)`
  font-size: 24px;
  margin: 10px 5px;
  color: #aeaeae;
  border: 1px solid #aeaeae;
  border-radius: 50%;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #1e1a4d;
    color: #ff6575;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const Login = ({ history }) => {
  let params = new URLSearchParams(window.location.search);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState();

  const getCustomState = () => {
    let customState = {};
    let continueParam = params.get('continue');

    if (continueParam) {
      customState.continue = continueParam;
    }

    return customState;
  };

  const onSignIn = async (username, password) => {
    setLoading(true);

    try {
      const user = await Auth.signIn(username, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        /*
        const { requiredAttributes } = user.challengeParam;
        const { username, email, phone_number } = getInfoFromUserInput();
        const loggedUser = await Auth.completeNewPassword(user, newPassword);
        */
        setFeedback({ type: 'info', message: 'You need to set a new password before you can sign in' });
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        history.push('/verify');
      } else if (err.code === 'PasswordResetRequiredException') {
        setFeedback({ type: 'info', message: 'You need to reset your password before you can sign in' });
      } else if (err.code === 'NotAuthorizedException') {
        setFeedback({ type: 'error', message: 'Incorrect username or password' });
      } else if (err.code === 'UserNotFoundException') {
        setFeedback({ type: 'error', message: 'Incorrect username or password' });
      } else {
        setFeedback({ type: 'error', message: 'An unexpected error occurred' });
      }
    }

    setLoading(false);
  };

  if (user) {
    return <Redirect to="/home" />;
  }

  return (
    <DefaultLayout>
      <Box>
        <InnerContainer>
          <LeftSection>
            <Logo draggable={false} src={logo} alt="pegora-logo" />
            <WelcomeTitle>Welcome Back!</WelcomeTitle>
            <IntroText>Enter your credentials or choose to sign in with a social provider.</IntroText>
            <Button ghost onClick={() => history.push('/signup')}>
              Sign Up
            </Button>
          </LeftSection>
          <RightSection>
            <SignInTitle>Sign in to Pegora</SignInTitle>
            <div>
              <SocialMediaIcon
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: 'Facebook',
                    customState: JSON.stringify(getCustomState())
                  })
                }
                type="facebook"
              />
              <SocialMediaIcon
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: 'Google',
                    customState: JSON.stringify(getCustomState())
                  })
                }
                type="google"
              />
            </div>
            <SignInDescription>
              <i>or use your email account</i>
            </SignInDescription>
            {feedback && <StyledAlert message={feedback.message} type={feedback.type} />}
            <LoginForm loading={loading} onSubmit={onSignIn} />
          </RightSection>
        </InnerContainer>
      </Box>
    </DefaultLayout>
  );
};

export default withRouter(Login);
