import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Auth } from 'aws-amplify';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'same-origin'
});

const authLink = setContext(async (request, { headers }) => {
  const session = await Auth.currentSession();
  return {
    headers: {
      ...headers,
      Authorization: session ? `Bearer ${session.idToken.jwtToken}` : ''
    }
  };
});

const link = ApolloLink.from([authLink.concat(httpLink)]);

const GraphQLClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

const GraphQLProvider = ({ children }) => (
  <ApolloProvider client={GraphQLClient}>
    <ApolloHooksProvider client={GraphQLClient}>{children}</ApolloHooksProvider>
  </ApolloProvider>
);

GraphQLProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default GraphQLProvider;
