import React from 'react';
import { Form, Icon, Input, Button } from '@golfersweden/pegora-ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ForgotPasswordLink = styled(Link)`
  margin-bottom: 24px;
  align-self: center;
`;

const Login = ({ loading, form, onSubmit }) => {
  const { getFieldDecorator } = form;

  return (
    <StyledForm
      onSubmit={e => {
        form.validateFields((err, values) => {
          if (!err) {
            onSubmit(values);
          }
        });

        e.preventDefault();
      }}
    >
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Enter your username' }]
        })(
          <Input
            size="large"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Enter your password' }]
        })(
          <Input
            size="large"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </Form.Item>
      <ForgotPasswordLink to="/forgot-password">Forgot your password?</ForgotPasswordLink>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit" shape="round" size="large" block>
          {loading ? 'Signing in' : 'Sign In'}
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default Form.create({ name: 'login_form' })(Login);
