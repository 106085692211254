import React, { Fragment, useState, useEffect } from 'react';
import { Alert, Form, Icon, Input, Button, Select, InputNumber } from '@golfersweden/pegora-ui';
import styled from 'styled-components';

const { Option } = Select;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const SignUp = ({ feedback, loading, form, onSubmit }) => {
  const [view, setView] = useState('BASIC_DETAILS');
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [gender, setGender] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const { getFieldDecorator } = form;

  useEffect(() => {
    if (view === 'BASIC_DETAILS')
      form.setFieldsValue({
        firstName,
        lastName,
        email,
        password,
        confirmPassword
      });
    else if (view === 'PERSONAL_DETAILS')
      form.setFieldsValue({
        day,
        year
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  return (
    <StyledForm
      onSubmit={e => {
        e.preventDefault();

        form.validateFields((err, values) => {
          if (!err) {
            onSubmit({
              firstName,
              lastName,
              email,
              birthday: new Date(year, month, day),
              password,
              gender
            });
          }
        });
      }}
    >
      {view === 'BASIC_DETAILS' && (
        <Fragment>
          <h2>Let's get you signed up!</h2>
          <p>Enter some basic information for your account</p>
          {feedback && <StyledAlert message={feedback.message} type={feedback.type} />}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Form.Item>
              {getFieldDecorator('firstName', {
                rules: [{ required: true, message: 'Enter first name' }]
              })(<Input onChange={e => setFirstName(e.target.value)} placeholder="First Name" />)}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('lastName', {
                rules: [{ required: true, message: 'Enter last name' }]
              })(<Input placeholder="Last Name" onChange={e => setLastName(e.target.value)} />)}
            </Form.Item>
          </div>

          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Enter e-mail' }]
            })(<Input placeholder="E-Mail" onChange={e => setEmail(e.target.value)} />)}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Enter password' },
                { min: 8, message: 'Your password must be at least 8 characters' }
              ]
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
              />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: 'Confirm password' },
                {
                  validator: (rule, value, callback) => {
                    if (value && value !== password) {
                      callback('Passwords must match');
                    } else {
                      callback();
                    }
                  }
                }
              ]
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Confirm password"
                onChange={e => setConfirmPassword(e.target.value)}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                form.validateFields((err, values) => {
                  if (!err) {
                    setView('PERSONAL_DETAILS');
                  }
                });
              }}
            >
              Next
            </Button>
          </Form.Item>
        </Fragment>
      )}
      {view === 'PERSONAL_DETAILS' && (
        <Fragment>
          <h2>Almost done, {firstName}!</h2>
          <p>Just some information about yourself </p>
          {feedback && <StyledAlert message={feedback.message} type={feedback.type} />}

          <Form.Item label="Your birthday">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20
              }}
            >
              <Select
                showSearch
                style={{ width: 125 }}
                placeholder="Month"
                optionFilterProp="children"
                onChange={val => setMonth(val)}
                value={month}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="1">January</Option>
                <Option value="2">February</Option>
                <Option value="3">Mars</Option>
                <Option value="4">April</Option>
                <Option value="5">May</Option>
                <Option value="6">June</Option>
                <Option value="7">July</Option>
                <Option value="8">August</Option>
                <Option value="9">September</Option>
                <Option value="10">October</Option>
                <Option value="11">November</Option>
                <Option value="12">December</Option>
              </Select>

              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('day', {
                  rules: [{ required: true, message: 'Enter day' }]
                })(<InputNumber placeholder="Day" min={1} max={31} onChange={val => setDay(val)} />)}
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('year', {
                  rules: [{ required: true, message: 'Enter year' }]
                })(
                  <InputNumber
                    placeholder="Year"
                    min={new Date().getFullYear() - 150}
                    max={new Date().getFullYear()}
                    onChange={val => setYear(val)}
                  />
                )}
              </Form.Item>
            </div>
            <Form.Item label="Your gender">
              <Select
                placeholder="Gender"
                optionFilterProp="children"
                onChange={val => setGender(val)}
                value={gender}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="female">Female</Option>
                <Option value="male">Male</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>

            <StyledButton
              onClick={() => {
                setView('BASIC_DETAILS');
              }}
            >
              Back
            </StyledButton>
            <StyledButton loading={loading} type="primary" htmlType="submit">
              {loading ? 'Signing up' : 'Sign up'}{' '}
            </StyledButton>
          </Form.Item>
        </Fragment>
      )}
    </StyledForm>
  );
};

export default Form.create({ name: 'login_form' })(SignUp);
