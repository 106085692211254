import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #f4f1eb;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const DefaultLayout = ({ children }) => {
  return <Container>{children}</Container>;
};

export default DefaultLayout;
