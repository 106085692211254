import React, { useState } from 'react';
import { Form, Icon, Input, Button } from '@golfersweden/pegora-ui';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChangePassword = ({ loading, form, onSubmit }) => {
  const { getFieldDecorator } = form;
  const [newPassword, setNewPassword] = useState();

  return (
    <StyledForm
      onSubmit={e => {
        form.validateFields((err, values) => {
          if (!err) {
            onSubmit(values.oldPassword, values.newPassword);
          }
        });

        e.preventDefault();
      }}
    >
      <Form.Item>
        {getFieldDecorator('oldPassword', {
          rules: [{ required: true, message: 'Enter your old password' }]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Old password"
            onChange={e =>
              form.setFieldsValue({
                username: e.target.value
              })
            }
            type="password"
            disabled={loading}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('newPassword', {
          rules: [
            { required: true, message: 'Enter a new password' },
            { min: 8, message: 'Your password must be at least 8 characters' }
          ]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            onChange={e => setNewPassword(e.target.value)}
            placeholder="New password"
            disabled={loading}
          />
        )}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator('confirmNewPassword', {
          rules: [
            { required: true, message: 'Confirm new password' },
            {
              validator: (rule, value, callback) => {
                if (value && value !== newPassword) {
                  callback('Passwords must match');
                } else {
                  callback();
                }
              }
            }
          ]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Confirm new password"
            disabled={loading}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {loading ? 'Submitting' : 'Submit'}
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default Form.create({ name: 'change_password_form' })(ChangePassword);
