import React from 'react';
import { Form, Icon, Input, Button } from '@golfersweden/pegora-ui';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ForgotPassword = ({ loading, form, onSubmit }) => {
  const { getFieldDecorator } = form;

  return (
    <StyledForm
      onSubmit={e => {
        form.validateFields((err, values) => {
          if (!err) {
            onSubmit(values.username);
          }
        });

        e.preventDefault();
      }}
    >
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Enter your username' }]
        })(
          <Input
            size="large"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
            onChange={e =>
              form.setFieldsValue({
                username: e.target.value
              })
            }
            disabled={loading}
          />
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" block htmlType="submit" loading={loading}>
          {loading ? 'Sending' : 'Send'}
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default Form.create({ name: 'init_forgot_password_form' })(ForgotPassword);
