import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Avatar } from '@golfersweden/pegora-ui';

const StyledAvatar = styled(Avatar)`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  ${props =>
    props.onSelectImage &&
    css`
      &:hover {
        cursor: pointer;
        opacity: 0.5;
        background-color: #000;
      }
    `}
`;

const FileUpload = styled.input`
  display: none;
`;

const ProfileImage = ({ onSelectImage, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState();

  if (!onSelectImage) {
    return <StyledAvatar alt="profile-image" icon="user" src={imgSrc || src} {...props} />;
  }

  return (
    <label htmlFor="upload">
      <FileUpload
        id="upload"
        type="file"
        onChange={e => {
          const file = e.target.files[0];

          const fr = new FileReader();

          fr.onload = () => {
            setImgSrc(fr.result);

            if (onSelectImage) {
              onSelectImage(fr.result);
            }
          };
          fr.onerror = error => console.error(error);
          fr.readAsDataURL(file);
        }}
        accept="image/png, image/jpeg"
      />
      <StyledAvatar alt="profile-image" icon="user" src={imgSrc || src} {...props} />
    </label>
  );
};

export default ProfileImage;
