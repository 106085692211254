import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { Alert, Button } from '@golfersweden/pegora-ui';
import { Auth } from 'aws-amplify';
import DefaultLayout from '../layouts/Default';
import Box from '../components/elements/Box';
import VerifyForm from '../components/forms/Verify';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 600px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ff5564;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const WelcomeTitle = styled.h1`
  color: white;
`;

const IntroText = styled.p`
  text-align: center;
  font-size: 16px;
  color: white;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const Verify = () => {
  const [verifying, setVerifying] = useState(false);
  const [redirectTo] = useState();
  const [feedback, setFeedback] = useState();
  const [display, setDisplay] = useState('VERIFY_FORM');
  const [username, setUsername] = useState();
  const [code, setCode] = useState();

  const verify = async (username, code) => {
    setVerifying(true);

    try {
      await Auth.confirmSignUp(username, code);
      setFeedback({
        message: 'Your account is now confirmed! Click below to go back to the sign in page.',
        type: 'success'
      });
      setDisplay('SIGN_IN_BUTTON');
    } catch (err) {
      if (err.code === 'NotAuthorizedException' && err.message.includes('Current status is CONFIRMED')) {
        setFeedback({
          message: 'Your account is already confirmed. Click below to go back to the sign in page.',
          type: 'info'
        });
        setDisplay('SIGN_IN_BUTTON');
      } else if (err.code === 'UserNotFoundException') {
        setFeedback({
          message: 'Could not find a user with the provided information to confirm, check your input and try again.',
          type: 'error'
        });
      } else {
        setFeedback({
          message: 'An unknown error occurred, please try again later.',
          type: 'error'
        });
      }
    }
    setVerifying(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const codeParam = params.get('code');
    const usernameParam = params.get('username');

    setUsername(usernameParam);
    setCode(codeParam);

    if (usernameParam && codeParam) {
      verify(usernameParam, codeParam);
    }
  }, []);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <DefaultLayout>
      <Box>
        <InnerContainer>
          <LeftSection>
            <h2>Verify account</h2>
            <Fragment>
              <p>Fill in your username and the provided confirmation code sent to your email.</p>
              {feedback && <StyledAlert message={feedback.message} type={feedback.type} />}

              {display === 'VERIFY_FORM' && (
                <VerifyForm
                  username={username}
                  code={code}
                  loading={verifying}
                  onSubmit={async (username, code) => await verify(username, code)}
                />
              )}

              {display === 'SIGN_IN_BUTTON' && (
                <Link to="/login">
                  <Button type="primary">Sign In</Button>
                </Link>
              )}
            </Fragment>
          </LeftSection>
          <RightSection>
            <WelcomeTitle>Already verified?</WelcomeTitle>
            <IntroText>
              If you already created and confirmed your account, click below to go to the sign in page instead.
            </IntroText>
            <Link to="/login">
              <Button ghost>Sign In</Button>
            </Link>
          </RightSection>
        </InnerContainer>
      </Box>
    </DefaultLayout>
  );
};

export default Verify;
