import React, { useState } from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import { Button } from '@golfersweden/pegora-ui';
import { Auth } from 'aws-amplify';
import DefaultLayout from '../layouts/Default';
import Box from '../components/elements/Box';
import SignUpForm from '../components/forms/SignUp';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 600px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ff5564;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const WelcomeTitle = styled.h1`
  color: white;
`;

const IntroText = styled.p`
  text-align: center;
  font-size: 16px;
  color: white;
`;
const SignUp = () => {
  const [redirectTo] = useState();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState();

  const onSubmit = async val => {
    setLoading(true);
    const birthdate =
      val.birthday.getFullYear() + '-' + ('0' + val.birthday.getMonth()).slice(-2) + '-' + val.birthday.getDate();

    try {
      await Auth.signUp({
        username: val.email,
        password: val.password,
        attributes: {
          email: val.email,
          birthdate,
          given_name: val.firstName,
          family_name: val.lastName,
          name: `${val.firstName} ${val.lastName}`,
          gender: val.gender
        }
      });

      setFeedback({
        type: 'success',
        message:
          'Your account was successfully created! We have sent a message to the email with information about how to verify your account.'
      });
    } catch (err) {
      setFeedback({
        type: 'error',
        message: err.message
      });
    }

    setLoading(false);
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <DefaultLayout>
      <Box>
        <InnerContainer>
          <LeftSection>
            <SignUpForm loading={loading} feedback={feedback} onSubmit={onSubmit} />
          </LeftSection>
          <RightSection>
            <WelcomeTitle>Already have an account?</WelcomeTitle>
            <IntroText>Click below to go to the sign in page instead.</IntroText>
            <Link to="/login">
              <Button ghost>Sign In</Button>
            </Link>
          </RightSection>
        </InnerContainer>
      </Box>
    </DefaultLayout>
  );
};

export default SignUp;
