import React from 'react';
import styled from 'styled-components';

const StyledInnerContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  width: 100%;
  max-width: 1120px;
`;

const InnerContainer = ({ children }) => <StyledInnerContainer>{children}</StyledInnerContainer>;

export default InnerContainer;
