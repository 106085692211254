import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import '@golfersweden/pegora-ui/dist/bundle.css';

import Login from './pages/Login';
import SignOut from './pages/SignOut';
import SignUp from './pages/SignUp';
import Verify from './pages/Verify';
import ForgotPassword from './pages/ForgotPassword';
import Authenticated from './layouts/Authenticated';
import { AuthProvider } from './components/auth/AuthContext';
import GraphQLProvider from './components/graphql/GraphQLProvider';
import './App.css';

function App() {
  return (
    <GraphQLProvider>
      <AuthProvider>
        <Router>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/signout" exact component={SignOut} />
            <Route path="/signup" exact component={SignUp} />
            <Route path="/verify" exact component={Verify} />
            <Route path="/forgot-password" exact component={ForgotPassword} />

            <Route path="/" component={Authenticated} />
            <Redirect exact from="/" to="/home" />
          </Switch>
        </Router>
      </AuthProvider>
    </GraphQLProvider>
  );
}

export default App;
