import React from 'react';
import { Menu } from '@golfersweden/pegora-ui';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const MainMenu = ({ location, history }) => {
  return (
    <Menu>
      <Menu.Divider />
      <Menu.Item key="3" onClick={async () => await Auth.signOut()}>
        Logga ut
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(MainMenu);
