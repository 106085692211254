import gql from 'graphql-tag';

export const ME = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      preferredUsername
      phoneNumber
      gender
      profileImageUrl
    }
  }
`;

export const UPDATE_PROFILE_IMAGE = gql`
  mutation UpdateProfileImageInput($input: UpdateProfileImageInput!) {
    updateProfileImage(input: $input)
  }
`;
