import React from 'react';
import { Menu, Icon } from '@golfersweden/pegora-ui';
import { withRouter } from 'react-router-dom';

const MainMenu = ({ location, history }) => {
  return (
    <Menu
      selectedKeys={[location.pathname]}
      onClick={({ key }) => history.push(key)}
      style={{ width: 256 }}
      mode="inline"
    >
      <Menu.Item key="/home">
        <Icon type="home" />
        Home
      </Menu.Item>
      <Menu.Item key="/profile">
        <Icon type="user" />
        Profile
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(MainMenu);
