import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 50px 20px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 0px 50px 20px rgba(214, 214, 214, 1);
  box-shadow: 0px 0px 50px 20px rgba(214, 214, 214, 1);
  overflow: hidden;
  background-color: #fff;
`;

const Box = ({ children }) => <Container>{children}</Container>;

export default Box;
