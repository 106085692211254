import React, { useEffect } from 'react';
import { Form, Icon, Input, Button } from '@golfersweden/pegora-ui';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Verify = ({ username, code, loading, form, onSubmit }) => {
  const { getFieldDecorator } = form;

  useEffect(() => {
    form.setFieldsValue({
      username,
      code
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, code]);

  return (
    <StyledForm
      onSubmit={e => {
        form.validateFields((err, values) => {
          if (!err) {
            console.log(values);
            onSubmit(values.username, values.code);
          }
        });

        e.preventDefault();
      }}
    >
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Enter your username' }]
        })(
          <Input
            size="large"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
            onChange={e =>
              form.setFieldsValue({
                username: e.target.value
              })
            }
            disabled={loading}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('code', {
          rules: [{ required: true, message: 'Enter your verification code' }]
        })(
          <Input
            size="large"
            prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Verification code"
            disabled={loading}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" block htmlType="submit" loading={loading}>
          {loading ? 'Verifying' : 'Verify'}
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default Form.create({ name: 'verify_form' })(Verify);
