import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  border: 1px solid #ebedf0;
  border-radius: 5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  padding: 10px;
`;

const Section = ({ children }) => <StyledSection>{children}</StyledSection>;

export default Section;
