import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { useLazyQuery } from 'react-apollo';
import { ME } from '../../graphql/Queries';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: process.env.REACT_APP_COOKIE_PATH,
      expires: parseInt(process.env.REACT_APP_COOKIE_EXPIRES)
    }
  }
});

const oauth = {
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  scope: ['email', 'profile'],
  redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
  redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
  responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE
};

Auth.configure({ oauth });

const Context = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [account, setAccount] = useState();

  const [getMe] = useLazyQuery(ME, {
    onCompleted: data => {
      setUser(data.me);
    }
  });

  useEffect(() => {
    const populateCurrentUser = () => {
      Auth.currentAuthenticatedUser()
        .then(account => {
          getMe();
          setAccount(account);
        })
        .catch(err => {
          // User is not authenticated
        });
    };

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          populateCurrentUser();
          break;
        case 'signOut':
          window.location.href = '/signout';
          break;
        case 'customOAuthState':
          const decoded = unescape(data);
          const customState = JSON.parse(decoded);

          if (customState.continue) {
            window.location.href = customState.continue;
          }
          break;
        default:
          break;
      }
    });

    populateCurrentUser();
  }, [getMe]);

  const defaultValue = {
    user,
    account
  };

  return <Context.Provider value={defaultValue}>{children}</Context.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

AuthProvider.defaultProps = {
  children: null
};

export const withUser = WrappedComponent => {
  // eslint-disable-next-line react/prefer-stateless-function
  return class extends React.Component {
    render() {
      return <Context.Consumer>{value => <WrappedComponent user={value.user} {...this.props} />}</Context.Consumer>;
    }
  };
};

export default Context;
