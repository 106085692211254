import React, { useEffect } from 'react';
import { Form, DatePicker, Input, Button, Select } from '@golfersweden/pegora-ui';
import styled from 'styled-components';

import InnerContainer from '../elements/InnerContainer';

const StyledForm = styled(Form)`
  flex-grow: 1;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const Profile = ({ form, onSubmit, user }) => {
  const { getFieldDecorator } = form;
  const { Option } = Select;

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        birthday: user.birthday
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <InnerContainer>
      <StyledForm onSubmit={() => {}}>
        <StyledFormItem label="Förnamn">
          {getFieldDecorator('firstName', {
            rules: [{ required: true, message: 'Vänligen ange ditt förnamn' }]
          })(<Input />)}
        </StyledFormItem>

        <StyledFormItem label="Efternamn">
          {getFieldDecorator('lastName', {
            rules: [{ required: true, message: 'Vänligen ange ditt efternamn' }]
          })(<Input />)}
        </StyledFormItem>

        <StyledFormItem label="E-Post">
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Vänligen ange en e-postadress' }]
          })(<Input />)}
        </StyledFormItem>

        <StyledFormItem label="Telefon">{getFieldDecorator('phone')(<Input />)}</StyledFormItem>

        <StyledFormItem label="Födelsedag">
          {getFieldDecorator('birthday', {
            rules: [{ message: 'Vänligen välj din födelsedag' }]
          })(<DatePicker placeholder="Välj datum" />)}
        </StyledFormItem>

        <StyledFormItem label="Kön">
          <Select placeholder="Välj kön" value={user.gender}>
            <Option value="male">Man</Option>
            <Option value="woman">Kvinna</Option>
            <Option value="other">Annat</Option>
          </Select>
        </StyledFormItem>

        <StyledFormItem>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </StyledFormItem>
      </StyledForm>
    </InnerContainer>
  );
};

export default Form.create({ name: 'profile_info_form' })(Profile);
