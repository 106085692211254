import React, { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Button } from '@golfersweden/pegora-ui';
import DefaultLayout from '../layouts/Default';
import Box from '../components/elements/Box';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 600px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ff5564;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const WelcomeTitle = styled.h1`
  color: white;
`;

const IntroText = styled.p`
  text-align: center;
  font-size: 16px;
  color: white;
`;
const Login = () => {
  const [redirectTo, setRedirectTo] = useState();

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <DefaultLayout>
      <Box>
        <InnerContainer>
          <LeftSection>
            <h1>Signed Out</h1>
            <p>You are now signed out. Click below to sign back in!</p>
            <Button type="primary" htmlType="submit" onClick={() => setRedirectTo('/login')}>
              Sign In
            </Button>
          </LeftSection>
          <RightSection>
            <WelcomeTitle>See you soon!</WelcomeTitle>
            <IntroText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a sem risus. Proin laoreet purus ut enim
              varius gravida. Suspendisse a enim erat. amet metus.
            </IntroText>
          </RightSection>
        </InnerContainer>
      </Box>
    </DefaultLayout>
  );
};

export default Login;
