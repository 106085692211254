import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown } from '@golfersweden/pegora-ui';

import Profile from '../pages/Profile';
import Home from '../pages/Home';
import AuthContext from '../components/auth/AuthContext';
import MainMenu from '../components/navigation/MainMenu';
import OptionsMenu from '../components/navigation/OptionsMenu';
import ProfileImage from '../components/user/ProfileImage';

const logo = require('../assets/logo_coral.png');

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;
  max-width: 100%;
  box-shadow: 0 2px 8px #f0f1f2;
  background-color: #1e1a4d;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
`;

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageContent = styled.div`
  display: flex;
  flex: 1;
  padding-top: 20px;
`;

const Logo = styled.img`
  width: 150px;
`;

const Admin = () => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <PageWrapper>
      <Header>
        <Logo draggable={false} alt="pegora-logo" src={logo} />
        <ActionBar>
          <Dropdown overlay={<OptionsMenu />} trigger={['click']}>
            <ProfileImage alt="profile-image" icon="user" src={user.profileImageUrl} />
          </Dropdown>
        </ActionBar>
      </Header>
      <PageContent>
        <MainMenu />
        <Route exact path="/Home" component={Home} />
        <Route exact path="/profile" component={Profile} />
      </PageContent>
    </PageWrapper>
  );
};

export default Admin;
