import React, { useState, useContext } from 'react';
import { Alert } from '@golfersweden/pegora-ui';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { Auth } from 'aws-amplify';
import AuthContext from '../components/auth/AuthContext';
import ProfileInfoForm from '../components/forms/Profile';
import Section from '../components/elements/Section';
import ChangePasswordForm from '../components/forms/ChangePassword';
import ProfileImage from '../components/user/ProfileImage';
import { UPDATE_PROFILE_IMAGE } from '../graphql/Queries';
import { ME } from '../graphql/Queries';

const InnerContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const StyledProfileImage = styled(ProfileImage)`
  margin: 10px;
  border: 1px solid #ebedf0;
`;

const Profile = () => {
  const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE, {
    update(
      cache,
      {
        data: { updateProfileImage }
      }
    ) {
      const me = cache.readQuery({ query: ME });

      cache.writeQuery({
        query: ME,
        data: { profileImageUrl: updateProfileImage, ...me }
      });
    }
  });

  const { user, account } = useContext(AuthContext);

  const [changePasswordFeedback, setChangePasswordFeedback] = useState();
  const [changingPassword, setChangingPassword] = useState(false);

  const changePassword = async (oldPassword, newPassword) => {
    setChangingPassword(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (err) {
      setChangePasswordFeedback({ type: 'error', message: err.message });
    }

    setChangingPassword(false);
  };

  const onProfileImageSubmit = async base64 => {
    updateProfileImage({ variables: { input: { base64 } } });
  };

  return (
    <InnerContent>
      <Section>
        <h1>Profile</h1>
        <StyledProfileImage
          size={125}
          alt="profile-image"
          icon="user"
          src={user.profileImageUrl}
          shape="circle"
          onSelectImage={base64 => onProfileImageSubmit(base64)}
        />

        <ProfileInfoForm user={user} />
      </Section>

      {account && !account.attributes.identities && (
        <Section>
          <h1>Change password</h1>
          {changePasswordFeedback && (
            <StyledAlert message={changePasswordFeedback.message} type={changePasswordFeedback.type} />
          )}

          <ChangePasswordForm loading={changingPassword} onSubmit={changePassword} />
        </Section>
      )}
    </InnerContent>
  );
};

export default Profile;
