import React, { useContext } from 'react';
import styled from 'styled-components';
import AuthContext from '../components/auth/AuthContext';

const InnerContent = styled.div`
  padding: 20px;
`;

const Home = () => {
  const { user } = useContext(AuthContext);

  console.log(user);

  return (
    <InnerContent>
      <h1>Hem</h1>
      <h5>{`Välkommen ${user.firstName}!`}</h5>
    </InnerContent>
  );
};

export default Home;
